// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-3-sixty-js": () => import("./../../../src/pages/work/3sixty.js" /* webpackChunkName: "component---src-pages-work-3-sixty-js" */),
  "component---src-pages-work-calypso-js": () => import("./../../../src/pages/work/calypso.js" /* webpackChunkName: "component---src-pages-work-calypso-js" */),
  "component---src-pages-work-com-unity-js": () => import("./../../../src/pages/work/com-unity.js" /* webpackChunkName: "component---src-pages-work-com-unity-js" */),
  "component---src-pages-work-covivre-js": () => import("./../../../src/pages/work/covivre.js" /* webpackChunkName: "component---src-pages-work-covivre-js" */),
  "component---src-pages-work-feamzy-js": () => import("./../../../src/pages/work/feamzy.js" /* webpackChunkName: "component---src-pages-work-feamzy-js" */)
}

